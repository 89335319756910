import cn from 'classnames';
import React from 'react';
import Clone from '../../../utils/clone';

interface IMainHeadlineProps {
  color?: string;
}

const MainHeadline: React.FC<React.PropsWithChildren<IMainHeadlineProps>> = ({
  children,
  color = 'text-grey-950 lg:text-primary-main',
}) => {
  return (
    <Clone
      className={cn(
        color,
        'text-xl font-black uppercase md:text-2xl lg:text-3xl'
      )}
    >
      {children}
    </Clone>
  );
};

export default MainHeadline;
