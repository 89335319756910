import { UseFormSetError } from 'react-hook-form';

/**
 * set error message in form for remote errors
 */
const setGlobalFormErrors = (
  error: any,
  setError: UseFormSetError<any>
): void => {
  const errorMessage = (() => {
    if (!error) return null;
    // Error type from our own api
    if (typeof error.type !== 'undefined') {
      return error.type;
    }

    // return string errors
    if (typeof error.message === 'string') {
      return error.message;
    }

    if (typeof error === 'string') {
      return error;
    }

    return 'Es ist ein Fehler aufgetreten. Probiere es noch einmal.';
  })();

  setError('global', { message: errorMessage });
};

export default setGlobalFormErrors;
