import React from 'react';
import { FieldErrors } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

interface IFormProps {
  errors: FieldErrors<any>;
}

const RemoteError: React.FC<React.PropsWithChildren<IFormProps>> = ({
  errors,
  children,
}) => {
  return (
    <>
      {errors && errors.global && (
        <div className="mb-1 mt-3 rounded-sm bg-red-500 px-4 py-2 text-text-dark-bg-contrast-white">
          <FormattedMessage id={`error:${errors.global.message}`} />
        </div>
      )}
      {children}
    </>
  );
};

export default RemoteError;
