import { SentryProvider } from 'contexts/sentry';
import { NextPage } from 'next';
import Login from '../../modules/auth/login';

const Product: NextPage = () => (
  <SentryProvider pageName="login">
    <Login />
  </SentryProvider>
);

export default Product;
