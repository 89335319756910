import cn from 'classnames';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import IconAnimatedSpinner from '../../../assets/icons/animated-spinner.svg';
import IconCircleError from '../../../assets/icons/circle-error.svg';
import Group from '../group';
import Label from '../label';
import ValidationErrors from '../validation-errors';

export interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  helper?: string | React.ReactNode;
  label?: React.ReactNode;
  labelClass?: string;
  showIcon?: boolean;
  [key: string]: any;
  name: string;
}

const Input: React.FC<InputProps> = ({
  className,
  placeholder,
  helper,
  required,
  formState,
  register,
  label,
  labelClass,
  showIcon = true,
  name,
  ...inputProps
}) => {
  const intl = useIntl();

  if (!formState) return null;

  const { errors, isValidating, touchedFields, isSubmitted } = formState;
  const touched = touchedFields[name];

  return (
    <Group>
      {label && (
        <Label className={labelClass} required={required} htmlFor={name}>
          {typeof label === 'string' ? (
            <FormattedMessage id={label} />
          ) : (
            <>{label}</>
          )}
        </Label>
      )}
      <div className="relative">
        <input
          {...register(name)}
          id={name}
          data-test={`input-${name}`}
          type={inputProps.type}
          placeholder={placeholder && intl.formatMessage({ id: placeholder })}
          className={cn(
            ' h-12 w-full rounded-lg border border-grey-300 bg-grey-50 px-4 py-3 text-base leading-tight text-text-light-bg-secondary hover:border-2 hover:border-grey-300 focus:border-2 focus:border-primary-main focus:outline-hidden',
            className,
            touched && 'border-2 border-primary-main',
            errors[name] &&
              isSubmitted &&
              'border-2 border-red-400 hover:border-red-400 focus:border-red-400'
          )}
        />
        {showIcon && (
          <div className="absolute bottom-0 right-0 top-0 flex h-full w-8 items-center ">
            {isValidating ? (
              <IconAnimatedSpinner className="h-auto w-5 text-grey-200" />
            ) : (
              <>
                {errors[name] && isSubmitted && (
                  <IconCircleError className="h-auto w-4" />
                )}
              </>
            )}
          </div>
        )}
      </div>
      {isSubmitted && (
        <ValidationErrors
          errors={errors[name]?.message}
          helper={helper}
          isSubmitted={isSubmitted}
        />
      )}
    </Group>
  );
};

export default Input;
