import cn from 'classnames';
import React from 'react';

interface PropTypes
  extends React.DetailedHTMLProps<
    React.LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  > {
  required?: boolean;
}

const Label: React.FC<PropTypes> = ({
  htmlFor,
  required,
  children,
  className,
  ...props
}) => {
  return (
    <label
      htmlFor={htmlFor}
      className={cn(
        'text-xs font-black uppercase tracking-wide text-primary-main	',
        className
      )}
      {...props}
    >
      {children}
      {required && (
        <sub className="ml-px align-text-top text-xl leading-3 text-red-600">
          *
        </sub>
      )}
    </label>
  );
};

export default Label;
