import React from 'react';
import { FormattedMessage } from 'react-intl';
import { StringSchema } from 'yup';

export interface ValidationErrorsProps {
  isSubmitted: boolean;
  helper?: string | React.ReactNode;
  errors: StringSchema;
}

const ValidationErrors: React.FC<ValidationErrorsProps> = ({
  errors,
  helper,
  isSubmitted,
}) => {
  return (
    <>
      {errors && isSubmitted ? (
        <div className="pt-1 text-xs text-text-light-bg-discounted-price">
          <FormattedMessage id={`validation:${errors}`} />
        </div>
      ) : (
        <>
          {helper && <div className="pt-1 text-xs text-text-light-bg-secondary">{helper}</div>}
        </>
      )}
    </>
  );
};

export default ValidationErrors;
