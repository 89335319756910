import { setContext, setTag } from '@sentry/browser';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

type defaultContextType = { [key: string]: any };

interface SentryContextType {
  mergeContextData: (arg: defaultContextType) => void;
}

const SentryContext = createContext<SentryContextType>({} as any);

type SentryContextInputType = {
  /** String key of tag and context */
  pageName: string;
};

/**
 * Context for Sentry.
 * Acts as wrapper of Sentry library to provide more detailed information when errors occur
 * by utilizing Tag and Context.
 * Tag and Context is flushed when the component is unmounted.
 * Instead of Sentry.getCurrentScope().clear(), internal state is used to keep other important context such as user information.
 * @see : https://docs.sentry.io/platforms/javascript/enriching-events/
 */
export const SentryProvider: React.FC<
  React.PropsWithChildren<SentryContextInputType>
> = ({ children, pageName }) => {
  /** @TODO : might need to use local storage to prevent delay after redirection */
  const [contextData, setContextData] = useState<defaultContextType>({});

  const mergeContextData = useCallback((arg) => {
    setContextData((pre) => {
      return {
        ...pre,
        ...arg,
      };
    });
  }, []);

  /**
   * Set up Tag to indicate page in Sentry logs.
   */
  useEffect(() => {
    setTag('page', pageName);
    return () => {
      setTag('page', null);
    };
  }, [pageName]);

  /**
   * Update Context to provide detailed information.
   */
  useEffect(() => {
    setContext(pageName, contextData);
    return () => {
      setContext(pageName, null);
    };
  }, [contextData, pageName]);

  return (
    <SentryContext.Provider
      value={{
        mergeContextData,
      }}
    >
      {children}
    </SentryContext.Provider>
  );
};

export const useSentryContext = () => useContext(SentryContext);
